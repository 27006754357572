import styles from './Card.module.css';
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from  "@dnd-kit/utilities";
import {useState} from "react";

export function SelectableCard(props) {
    const {cardInfo, selected, setSelected, play} = props;

    const [isSelected, setIsSelected] = useState(false);

    function handleClick(e)  {
        console.log("Card clicked");
        play(cardInfo.id);

        let currentParent= e.target;
        for (let i = 0; i < e.target.parentElement.children.length; i++) {
            console.log(currentParent);
            if (currentParent.id === cardInfo.id) {
                console.log("Card selected");
                currentParent.children[0].style.background = "rgba(39,62,84,0.82)";
            } else {
                currentParent = currentParent.parentElement;
            }
        }
    }


    return (
        <div id={cardInfo.id} onClick={handleClick} style={selected ? {background: "rgba(39,62,84,0.82)"} : null}>
            <Card cardInfo={cardInfo}/>
        </div>
    )
}

export function SortableCard(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    return (
        <div key={props.cardInfo.id} ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Card cardInfo={props.cardInfo} />
        </div>
    );
}


function Card(props) {
    const {cardInfo} = props;
    if (cardInfo === undefined) {
        return null;
    } else
        return (
            <div className={styles.card} id={cardInfo.id}>
                <div className={styles.card__photo}>
                    <svg version="1.0"
                         x="0px"
                         y="0px"
                         viewBox="0 0 100 95.152"
                         enableBackground="new 0 0 100 95.152"
                         className="woof">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M75.754,91.754c-11.632,1.836-9.73-4.492-9.864-11.711l-0.686-14.15l-2.987,13.671
                                c-0.545,2.594-1.23,4.972,2.446,6.876c2.59,2.248-0.332,5.448-3.121,5.037c-8.518,0.277-7.284-5.376-6.548-9.385l0.479-5.79
                                c0.343-3.944,1.093-6.4,1.295-11.913l-1.15-2.988c-9.188-2.381-15.524-4.152-22.664-7.962l-5.114,0.679
                                c-2.311,0.342-1.692,3.066-1.966,4.834l-0.747,4.827l-4.228,10.961l-0.331,0.952c-0.279,0.754-0.211,3.745,0.886,5.304l2.237,4.561
                                c1.77,2.59,2.244,8.987-2.515,6.938l-3.541-1.49c-2.513-1.021-4.562-8.028-5.436-13.133l0.069-7.151
                                c-2.183-0.675-3.07,12.191-2.183,14.836l2.183,6.675c0.671,2.168-1.84,2.922-3.61,2.922c-4.154,0-5.992-4.218-6.876-7.76
                                l-0.675-2.646c-1.03-4.084-0.488-10.285,1.625-13.894l0.951-2.233c1.903-4.431-0.41-7.631-0.951-9.878
                                c-1.77-7.216-3.2-14.225,1.771-21.167l0.346-0.475c-0.144-0.542-1.576-1.695-2.46-4.417c-1.907-6.133-3.608-16.197-1.22-16.197
                                l1.562-0.077c4.021,0,2.854,16.607,7.627,15.522l34.574,0.343l6.056-1.64c2.25-0.606,4.152-2.035,6.122-3.264l3.94-2.447
                                c4.561,4.562,8.514,7.352,15.045,9.732c1.774,13.537-0.199,16.54-1.902,24.628l-0.688,3.269c-1.216,5.787-1.627,11.779-1.35,17.7
                                l0.134,2.789c-0.065,2.857,1.559,3.06,3.807,3.741C77.526,87.536,78.543,90.394,75.754,91.754L75.754,91.754L75.754,91.754z">
                        </path>
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M83.062,9.073c1.901,1.297,4.286,3.608,6.191,5.646l2.388,2.592
                                c1.562,1.625,3.94,1.425,6.055,1.834l0.743,0.135c2.312,1.218,1.576,3.673,0.819,5.512l-0.477,1.153
                                c-2.183,5.247-8.645,5.657-13.208,3.198l-2.035-1.083H82.85l-1.422,0.952l-2.927,2.314c-5.92-2.591-10.751-5.714-14.637-9.866
                                l8.922-11.167l2.991-4.352l0.61-1.43l0.686-1.572c0.544-1.759,2.788-3.464,4.43-2.789c1.559,0.686,2.58,3.674,2.169,5.249
                                l-0.408,1.77L83.062,9.073L83.062,9.073L83.062,9.073z">
                            </path>
                    </svg>
                </div>
                <div className={styles.card__info}>
                    <h2>{cardInfo.name}</h2>
                    <p>{cardInfo.hp + " hp"}</p>
                    <p>{cardInfo.attack + " attq"}</p>
                </div>
            </div>
        );
}

export default Card;